import React from 'react'
import logo from '../assets/logo/favicon-32x32.png'
import errorimg from '../assets/temp/error.svg'
import { Link } from 'gatsby';


export default function Footer() {
    return (
        <div className="flex flex-col md:flex-row justify-between pt-10 pb-12 px-4 lg:px-16 bg-gray-100">
            <div className="flex flex-col mx-4 md:my-auto">
                <div className="text-left mt-4">
                    <img className="max-h-9 w-auto" src={logo}></img>
                </div>
                <div className="mt-4 w-full md:w-4/6">
                    <p className="text-md 4xl:text-xl text-gray-600">
                        <strong className="text-darkblue-tf">Timefree</strong> is an enterprise software company that provides cloud solutions, data analytics platforms,
                        and productivity applications.
                    </p>

                    <p className="head-font font-medium text-md 4xl:text-xl mt-1 text-blue-tf">
                        Helping businesses scale since 2013.</p>
                </div>
            </div>
            <div className="flex flex-col mx-4 lg:mr-0">
                <div className="flex flex-row  mt-8 md:mt-0 md:justify-end items-center md:items-end gap-x-2">
                    {/* <SocialMediaIconsReact borderColor="#103f4e" borderWidth="0" borderStyle="solid" icon="facebook" iconColor="#fff" backgroundColor="#103f4e" iconSize="2" url="https://www.facebook.com/TimeFree.Innovations" roundness="20%" size="36" /> */}
                    {/* <SocialMediaIconsReact borderColor="#103f4e" borderWidth="0" borderStyle="solid" icon="instagram" iconColor="#fff" backgroundColor="#103f4e" iconSize="2" url="https://www.instagram.com/timefreeph/" roundness="20%" size="36" /> */}
                    {/* <SocialMediaIconsReact borderColor="#103f4e" borderWidth="0" borderStyle="solid" icon="linkedin" iconColor="#fff" backgroundColor="#103f4e" iconSize="2" url="https://www.linkedin.com/company/timefree/" roundness="20%" size="36" /> */}
                </div>
                <div className="flex flex-col md:text-right xl:mr-4">
                    <a href="mailto:info@timefree.ph">
                        <p className="text-darkblue-tf font-semibold text-md 4xl:text-xl mt-3">
                            info@timefree.ph
                        </p>
                    </a>
                    <span className="text-darkblue-tf font-semibold text-md 4xl:text-xl my-1">
                        <a href="tel:+63286923890">(+632) 8692 3890</a> <span className="text-gray-600">·</span> <a href="tel:+63270011074">(+632) 7001 1074</a>
                    </span>
                    <p className="text-sm font-base 4xl:text-lg text-gray-600 my-1">
                        3B Trans-Phil Bldg., Bagtikan St.
                        cor. Chino Roces Ave., Makati City, Philippines, 1203
                    </p>
                    <div className="flex-none 2xl:flex justify-start md:justify-end my-1">
                        <p className="text-sm 4xl:text-lg text-gray-600 mr-0 2xl:mr-2">
                            © 2024 TimeFree Innovations, Inc. All rights reserved.
                        </p>
                        <Link to="/privacy-policy" className="text-darkblue-tf font-bold text-sm 4xl:text-lg">Privacy Policy</Link>

                    </div>
                </div>
            </div>
        </div>
    )
}
