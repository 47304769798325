import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./navbar"
import "./layout.scss"
import Footer from "./footer"

export default function Layout({ children }) {
  return (
    <div className="layout flex flex-col min-h-screen">
      <Navbar className="absolute top-0" />
      <div className="content flex-grow">
        {children}
      </div>
      <Footer className="flex" />
    </div>
  )
}