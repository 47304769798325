/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Popover, Transition } from '@headlessui/react';
import { ExclamationIcon, MenuIcon, XIcon } from '@heroicons/react/outline';
import timefree_logo from '../assets/logo/2024/timefree2024_full_color.svg';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';

const navigation = [
  { name: 'Home', href: '/#' },
  {
    name: 'Products', href: '/#products', sublist: [
      { name: 'Queue Management', href: '/products/qapps' },
      // { name: 'Contact Tracing', href: '/products/qsafe' },
      { name: 'Human Resource', href: '/products/financia' },
      { name: 'Attendance Monitoring', href: '/products/tapio' },
      { name: 'Message Broadcasting', href: '/products/sobre' }
    ], isButton: false
  },
  { name: 'Services', href: '/howwecanhelp', isButton: false },
  { name: 'Clients', href: '/#clients', isButton: false },
  { name: 'Partners', href: '/#partners', isButton: false },
  { name: 'About Us', href: '/#aboutus', isButton: false },
  { name: 'Contact Us', href: '/contact', isButton: true },
]

export default function Navbar() {
  const [showProductModal, setShowProductModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({
    "image": "../products/qmobile2.jpeg",
    "title": "Queue Management",
    "subtitle": "QApps + QMobile",
    "description": "<strong>QApps</strong> is a queue management solution that enables your business to manage high customer traffic in your store or office and monitor staff productivity and efficiency. Qmobile is queue slot reservation and appointment booking app to help your business provide excellent customer queuing experience and increase customer engagements.",
    "price": "Price starts at <strong>PHP 750.00</strong> / branch / month.",
    "href": "https://play.google.com/store/apps/details?id=com.innovations.timefree.qmobile&hl=en&gl=US",
    "bgcolor": "rgba(6, 161, 216, 0.1)",
    "fontcolor": "#4B5563"
  });
  const cancelButtonRef = useRef(null);
  const viewButtonRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [navShadow, setNavShadow] = useState('');
  const [openPopOver, setOpenPopOver] = useState(false);
  const buttonRef = useRef();

  useEffect(() => {
    window.onscroll = () => {
      // setOffset(window.pageYOffset)
      if (window.pageYOffset > 30) {
        // user is scrolled
        setNavShadow('shadow-md ease-in');
      } else {
        // user is at top of page
        setNavShadow('');
      }
    }
  }, []);

  function onClickHref(href) {
    navigate(`${href}`);
  }

  function closeButtonClicked() {
    console.log('close');
    buttonRef.current.click();
  }

  return (
    <>
      <div className={`bg-gray-trans backdrop-filter backdrop-blur overflow-visible sticky top-0 z-50 transition-shadow ${navShadow}`}>
        <div className="max-w-full mx-auto">
          <div className="relative z-30 w-full bg-transparent py-4 md:py-6">
            <Popover>
              {({ open }) => (
                <>
                  <div className="relative px-6 sm:px-8 lg:px-16 z-20">
                    <nav
                      className="relative flex items-center justify-between sm:h-10 z-20"
                      aria-label="Global"
                      role="navigation"
                    >
                      <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0 relative">
                        <div className="flex items-center justify-between w-full ml:w-auto">
                          <Link to="/#">
                            <span className="sr-only">TimeFree</span>
                            <img
                              className="h-5 lg:h-7 w-auto"
                              src={timefree_logo}
                            />
                          </Link>
                          <div className="-mr-2 flex items-center ml:hidden">
                            <Popover.Button ref={buttonRef} className="bg-gray-50 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-darkblue-tf">
                              <span className="sr-only">Open main menu</span>
                              <MenuIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                      </div>
                      <div className="hidden ml:block ml:ml-10 ml:pr-4 ml:space-x-8">
                        {navigation.map((item) => {
                          if (item.sublist)
                            return (
                              <div key={item.name} className="group 4xl:text-xl text-gray-500 hover:text-gray-900 inline-block relative cursor-pointer" onClick={() => onClickHref(item.href)}>
                                {item.name}
                                <ul className="absolute hidden text-gray-700 pt-1 group-hover:block">
                                  {item.sublist.map((sub, i) => {
                                    return (
                                      <li className="" key={i.toString()} onClick={() => closeButtonClicked()} >
                                        <Link className={"text-left 4xl:text-xl bg-white shadow-md hover:bg-lightblue-trans-tf py-3 px-4 block whitespace-no-wrap " + (i == 0 ? "rounded-t-md" : i == item.sublist.length - 1 ? "rounded-b-md" : "")}
                                          to={sub.href}
                                        >{sub.name}</Link>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                            )
                          else if (item.isButton)
                            return (
                              <Link key={item.name} to={item.href} className="inline-flex items-center justify-center px-5 py-2 border 4xl:text-xl font-medium rounded-md text-blue-tf border-blue-tf hover:bg-darkblue-tf hover:border-darkblue-tf hover:text-gray-50">
                                {item.name}
                              </Link>
                              // <Link key={item.name} to={item.href} className="text-blue-tf hover:text-darkblue-tf font-bold text-lg">
                              //   {item.name}
                              // </Link>
                            )
                          else
                            return (
                              <Link key={item.name} to={item.href} className="4xl:text-xl text-gray-500 hover:text-gray-900">
                                {item.name}
                              </Link>
                            )
                        }
                        )
                        }
                      </div>
                    </nav>
                  </div>

                  <Transition
                    show={open}
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Popover.Panel
                      onClick={() => closeButtonClicked()}
                      focus
                      static
                      className="absolute top-0 inset-x-0 p-2 transitionorigin-top-right ml:hidden overflow-visible z-50"
                    >
                      <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-visible">
                        <div className="px-5 pt-4 flex items-center justify-between">
                          <div>
                            <img
                              className="h-5 lg:h-7 w-auto"
                              src={timefree_logo}
                              alt=""
                            />
                          </div>
                          <div className="-mr-2">
                            <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-darkblue-tf">
                              <span className="sr-only">Close main menu</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </Popover.Button>
                          </div>
                        </div>
                        <div className="px-2 pt-2 pb-3 space-y-1">
                          {navigation.map((item) => {
                            // if (item.sublist)
                            //   return (
                            //     <div key={item.name} className="group block px-3 py-2 rounded-md text-base text-gray-700 hover:text-gray-900 hover:bg-gray-50 inline-block relative">
                            //       {item.name}
                            //       <ul className="absolute hidden text-gray-700 pt-1 group-hover:block">
                            //         {item.sublist.map((sub, i) => {
                            //           return (
                            //             <li className="" key={i.toString()}>
                            //               <Link className={"bg-white shadow-md hover:bg-lightblue-trans-tf py-3 px-4 block whitespace-no-wrap " + (i == 0 ? "rounded-t-md" : i == item.sublist.length - 1 ? "rounded-b-md" : "")}
                            //                 to={sub.href}
                            //               >{sub.name}</Link>
                            //             </li>
                            //           )
                            //         })}
                            //       </ul>
                            //     </div>
                            //   )
                            // else
                            if (item.isButton)
                              return (
                                <Link key={item.name} to={item.href} onClick={() => closeButtonClicked()} className="inline-flex items-center justify-center px-5 py-2 border font-medium rounded-md text-blue-tf border-blue-tf hover:bg-darkblue-tf hover:text-gray-50">
                                  {item.name}
                                </Link>
                              )
                            return (
                              <Link
                                key={item.name}
                                to={item.href}
                                onClick={() => closeButtonClicked()}
                                className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                              >
                                {item.name}
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </>
  )
}
